module.exports = [{
      plugin: require('/codebuild/output/src647818574/src/interactive-gallery-v2/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingID":"UA-41426299-11"},
    },{
      plugin: require('/codebuild/output/src647818574/src/interactive-gallery-v2/node_modules/gatsby-plugin-modal-routing/gatsby-browser.js'),
      options: {"plugins":[],"modalProps":{"shouldCloseOnOverlayClick":true,"shouldCloseOnEsc":true,"onRequestClose":{},"style":{"overlay":{"position":"fixed","top":"0","left":"0","right":"0","bottom":"0","backgroundColor":"rgba(0, 0, 0, 0.75)"}}}},
    },{
      plugin: require('/codebuild/output/src647818574/src/interactive-gallery-v2/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":600,"quality":100,"wrapperStyle":"margin-left: 0px; margin-right: 0px;"},
    },{
      plugin: require('/codebuild/output/src647818574/src/interactive-gallery-v2/node_modules/gatsby-plugin-modal-routing/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/codebuild/output/src647818574/src/interactive-gallery-v2/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/codebuild/output/src647818574/src/interactive-gallery-v2/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
